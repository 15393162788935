//import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faCar, faTrain, faBus, faWalking , faBicycle} from '@fortawesome/free-solid-svg-icons';
//import { accommodations } from '../../constants/places';
import './Venue.css';
import mct_courtyard from '../../assets/images/mct_courtyard1.png';
//import map_leuven from '../../assets/images/map_leuven.jpg';

const Venue = () => {
//    const [categoryFilter, setCategoryFilter] = useState(null);

/*    const handleFilterChange = (category) => {
        console.log("Category selected:", category);
        setCategoryFilter(category);
    };*/

    /*const filteredAccommodations = categoryFilter
        ? accommodations.filter(acc => acc.category === categoryFilter)
        : accommodations;*/

    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                            Venue & Accommodation
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className="twocols-container twocols-container-inverse">
                        <div className="regular regular-special">
                            <h2 className="twocols-title twocols-title-special">Venue</h2>
                            <p className="regular-description">The conference will take place on university grounds in the city center of Leuven, Belgium. The conference activities are organized in and around the Maria-Theresia College (MTC) in Leuven, the registration desk will be located in MTC 00.03. Due to the central location of the conference and Leuven’s limited circumference, the majority of the city’s amenities are in walking distance of the conference venue.</p>
                        </div>
                        <div className="twocols-image">
                            {/* <img src={map_leuven} alt="map_leuven" /> */}
                            <img src={mct_courtyard} alt="mct_courtyard" />
                            <p className="image-caption"><a href="https://www.google.com/maps?ll=50.876901,4.701617&z=13&t=m&hl=fr&gl=BE&mapclient=embed&cid=13057681970558682319" target="_blank" rel="noreferrer">Google map</a></p>
                            {/* <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23897.766771382034!2d4.686621670425482!3d50.87347910150637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c160d81af3fbe7%3A0xb5363491d52c44cf!2sMTC1%20Maria-Theresiacollege!5e0!3m2!1sfr!2sbe!4v1727702026550!5m2!1sfr!2sbe"
                                width="450"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe> */}
                        </div>
                    </div>
                {/* </div> */}
                {/* <div className="ContType4 HomeCont"> */}
                    <div className='venue-types'>
                        <div className="twocols-container twocols-special">
                            <div className="threecolumn">
                                <h3>By Plane <FontAwesomeIcon icon={faPlane} /></h3>
                                <p>International visitors can easily reach Leuven by plane. The closest airport is Brussels airport Zaventem. Upon landing, visitors can catch a train which regularly commutes between the airport and Leuven, a ride which lasts no longer than 25 minutes. </p>
                                <p>Landing in Brussels airport Charleroi is less direct. The visitor would have to take a bus from the airport to Fleurus, and then take the train to Leuven, a trip which typically takes 2 hours.</p>
                            </div>
                            <div className="threecolumn">
                                <h3>By Car <FontAwesomeIcon icon={faCar} /></h3>
                                <p>Leuven is situated about 25 kilometers east of Brussels and is easily reachable via major highways, including the E40 and E314. These routes provide quick connections to Brussels and other nearby cities, and the roads in Belgium are free of tolls. Leuven has several parking facilities, both on-street and in public parking garages. Note however that traffic in and around Leuven can be heavy during peak hours.</p>
                            </div>
                            <div className="threecolumn">
                                <h3>By Train <FontAwesomeIcon icon={faTrain} /></h3>
                                <p>The train station is a short walk (15 minutes) from the city center. Most international visitors will find themselves a connection to Leuven from Brussels, though direct routes to Leuven may also be relevant. To find the route most relevant for you, please consult the website of the <a href="https://www.belgiantrain.be/en" target="_blank" rel="noreferrer">Belgian railways</a>.</p> 
                                <p>From the train station, there are many busses which can be relied on to take you to your destination in the city center. Bus schedules can be found on the website of <a href="https://www.delijn.be/en/" target="_blank" rel="noreferrer">De Lijn</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ContType2 HomeWideCont">
                    <div className="twocols-container twocols-container-inverse">
                        <div className="regular regular-special">
                            <h2 className="twocols-title twocols-title-special">Accommodation</h2>
                            {/* <p className="regular-description">For visitors desiring accommodation outside the city center (e.g. in Heverlee), bus networks can be used to commute to and from the conference, and it is also possible to rent bicycles from private rental companies. The busses remain active until around midnight. More information is provided below.</p> */}
                            <p><strong>We will soon provide an overview of possible accommodations in Leuven</strong> and their distance to the conference venue.</p>
                        </div>
                        <div className="twocols-image">
                            {/* <img src={mct_courtyard} alt="mct_courtyard" /> */}
                            {/* <img src={map_leuven} alt="map_leuven" /> */}
                            {/* <p className="image-caption">Maria-Theresia College courtyard</p> */}
                        </div>
                    </div>
                    <div className='venue-types'>
                        <div className="twocols-container twocols-special">
                            <div className="threecolumn">
                                <h3>By Foot <FontAwesomeIcon icon={faWalking} /></h3>
                                <p>Due to the small size of Leuven, most amenities in the city center can be reached by foot. For instance, the conference venue is a 15 minute walk from the train station. Furthermore, policies discouraging traffic in the city center and the many beautiful buildings make walking a pleasant way to get around town. You can consult a  <a href='https://visitleuven.be/en/city-map' target="_blank" rel="noreferrer">city map</a>, or visit Leuven, Naamsestraat 3.</p>
                            </div>
                            <div className="threecolumn">
                                <h3>By Bike <FontAwesomeIcon icon={faBicycle} /></h3>
                                <p>Bike rental in Leuven is possible with Blue-Bike. You can find a pick-up point near the Leuven train station inside the bicycle parking, Prof. R. Van Overstraetenplein 1/001, 3000 Leuven. For the first 24 hours you can already rent a bike for €2,50. Different rates apply after 24 hours which you can consult on their <a href='https://www.blue-bike.be/en/' target="_blank" rel="noreferrer">website</a>. </p>
                            </div>
                            <div className="threecolumn">
                                <h3>By Bus <FontAwesomeIcon icon={faBus} /></h3>
                                <p>Leuven boasts an extensive bus network. Operated by De Lijn, the public transportation system offers a variety of routes connecting key areas, including the city center, university campuses, and neighboring towns. Buses typically run every 15 to 30 minutes, depending on the time of day and route. To make the most of the system, passengers can use the De Lijn app or <a href="https://www.delijn.be/en/" target="_blank" rel="noreferrer">website</a> to plan their journeys, check real-time schedules, and purchase tickets. Tickets can be bought at vending machines, online, or through the app. </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* TODO: */}
                {/* <div className="ContType3 HomeWideCont">
                    <div className="regular">
                        <h2 className="regular-title">Hotel information</h2>
                        <div className="filter-container"> */}
                            {/* <div className="filter-button">
                                <button onClick={() => handleFilterChange(null)}>All</button>
                                <button onClick={() => handleFilterChange("50-100")}>50-100</button>
                                <button onClick={() => handleFilterChange("100-200")}>100-200</button>
                                <button onClick={() => handleFilterChange("200-300")}>200-300</button>
                            </div> */}
                        {/* </div>
                        <div className="accommodation-gallery">
                            {filteredAccommodations.map(acc => (
                                <div key={acc.id} className="accommodation-card">
                                    <Link to={acc.link} target="_blank" rel="noopener noreferrer" className="accommodation-link">
                                        <img src={acc.src} alt={acc.name} className="accommodation-image" />
                                        <div className="accommodation-info">
                                            <h3>{acc.name}</h3>
                                            <p>Price: {acc.price}€</p>
                                            <p><FontAwesomeIcon icon={faWalking} /> {acc.distanceFoot}</p>
                                            <p><FontAwesomeIcon icon={faBus} /> {acc.distancePublic}</p>
                                            <p>{acc.info}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                
            </div>
        </div>
    );
}

export default Venue;
