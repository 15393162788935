
import { img_team } from './images';

export const teams = {
    Seniors: [
        { name: "Peter Kuppens", role: "Professor", image: img_team.pic_kuppens, link: "https://www.google.com"  },
        { name: "Ines Myin-Germeys", role: "Professor", image: img_team.pic_myin, link: "https://www.google.com"  },
        { name: "Olivia Kirtley", role: "Professor", image: img_team.pic_kirtley, link: "https://www.google.com"  },
        { name: "Ginette Lafit", role: "Professor", image: img_team.pic_lafit, link: "https://www.google.com"  },
    ],
    Scientific: [
        {name: "Michal Hajdúk", role: "Associate Professor", image: img_team.pic_hajduk, link: "https://www.google.com"  },
        {name: "Esther Ulitzsch", role: "Associate Professor", image: img_team.pic_ulitzsch, link: "https://www.google.com"  },
        {name: "Aleksa Kaurin", role: "Professor", image: img_team.pic_kaurin, link: "https://www.google.com"  },
        {name: "Bert Lenaert", role: "Assistant Professor", image: img_team.pic_lenaert, link: "https://www.google.com"  },
        {name: "Robin Achterhof", role: "Postdoc", image: img_team.pic_achterhof, link: "https://www.google.com"  },
        {name: "Marieke Schreuder", role: "Postdoc", image: img_team.pic_schreuder, link: "https://www.google.com"  },
        {name: "Koen Niemeijer", role: "Postdoc", image: img_team.pic_niemeijer, link: "https://www.google.com"  },
        {name: "Ann Desmet", role: "Professor", image: img_team.pic_desmet, link: "https://www.google.com"  },
        {name: "Eisele Gudrun", role: "Postdoc", image: img_team.pic_gudrun, link: "https://www.google.com"  },
        {name: "Joanne Beames", role: "Postdoc", image: img_team.pic_beames, link: "https://www.google.com"  },
    ],
    Social: [
        { name: "Eveline van Raes", role: "PhD", image: img_team.pic_vanraes, link: "https://www.google.com"  },
        { name: "Joanne Beames", role: "Postdoc", image: img_team.pic_beames, link: "https://www.google.com"  },
        { name: "Benjamin Kunc", role: "PhD", image: img_team.pic_kunc, link: "https://www.google.com"  },
    ],
    Communication: [
        { name: "Sigert Ariens", role: "Postdoc", image: img_team.pic_ariens, link: "https://www.google.com"  },
        { name: "Eisele Gudrun", role: "Postdoc", image: img_team.pic_gudrun, link: "https://www.google.com"  },
        { name: "Rana Kalkan", role: "PhD", image: img_team.pic_kalkan, link: "https://www.google.com"  },
        { name: "Jordan Revol", role: "PhD", image: img_team.pic_revol, link: "https://www.google.com"  },
        { name: "Otto Versyp", role: "PhD", image: img_team.pic_versyp, link: "https://www.google.com"  },
        { name: "Lotte Uyttebroek", role: "PhD", image: img_team.pic_uyttebroek, link: "https://www.google.com"  },
        { name: "Liesse Frérart", role: "PhD", image: img_team.pic_frerart, link: "https://www.google.com"  },
        { name: "Koen Niemeijer", role: "Postdoc", image: img_team.pic_niemeijer, link: "https://www.google.com"  },
        { name: "Nieke Vermaelen", role: "PhD", image: img_team.pic_vermaelen, link: "https://www.google.com"  },
    ],
    Logistics: [
        { name: "Laura van Heck", role: "PhD", image: img_team.pic_vanheck, link: "https://www.google.com"  },
        { name: "Lisa Peeters", role: "PhD", image: img_team.pic_peeters, link: "https://www.google.com" },
        { name: "Mirthe Luijsmans", role: "PhD", image: img_team.pic_luijsmans, link: "https://www.google.com"  },
        { name: "Zhiwei Dou", role: "PhD", image: img_team.pic_dou, link: "https://www.google.com" },
        { name: "Martine Verhees", role: "Postdoc", image: img_team.pic_verhees, link: "https://www.google.com"  },
        { name: "Yuanyuan Ji", role: "PhD", image: img_team.pic_ji, link: "https://www.google.com"  },
    ]
};