import React from 'react';
import './Contact.css';
// import speakerExample from '../../assets/images/speaker_example.jpeg';
import { teams } from '../../constants/team';



const Contact = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Contact & Team
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className="main-regular regular">
                        <p className="regular-description p-justify-texts">For general inquiries about the conference, please send an e-mail to <a href="mailto:info@SAA2025.com">info@SAA2025.com</a> and we will get back to you soon.</p>
                    </div>
                    <div className="research-groups-container">
                        <h2 className="regular-title research-title">Organising Research groups (KU Leuven)</h2>
                        <div className="research-groups">
                            <a href="https://gbiomed.kuleuven.be/english/research/50000666/50000673/cpp/index.htm" className="research-group" target="_blank" rel="noopener noreferrer">
                                <h3 className="research-group-title">Center for Contextual Psychiatry (CCP)</h3>
                                {/* <p className="research-group-description">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers.</p> */}
                            </a>
                            <a href="https://ppw.kuleuven.be/okp/home/" className="research-group" target="_blank" rel="noopener noreferrer">
                                <h3 className="research-group-title">Research Group of Quantitative Psychology and Individual Differences (OKP)</h3>
                                {/* <p className="research-group-description">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers.</p> */}
                            </a>
                            <a href="https://www.kuleuven.be/samenwerking/real" className="research-group" target="_blank" rel="noopener noreferrer">
                                <h3 className="research-group-title">Research on Experience sampling and Ambulatory methods Leuven (REAL)</h3>
                                {/* <p className="research-group-description">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers.</p> */}
                            </a>
                            <a href="https://ppw.kuleuven.be/mesrg" className="research-group" target="_blank" rel="noopener noreferrer">
                                <h3 className="research-group-title">Methodology of Educational Sciences Research Group (MESRG) </h3>
                                {/* <p className="research-group-description">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers.</p> */}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="ContType2 HomeWideCont">
                    <h2 className="regular-title">Teams</h2>
                    <div className="trombinoscope-container">
                        {Object.keys(teams).map(team => (
                            <div key={team} className="team-section">
                                <h2 className="team-title">{team.charAt(0).toUpperCase() + team.slice(1)}</h2>
                                <div className="team-members">
                                    {teams[team].map(member => (
                                        // TODO: if decided to add links to the team members, remove the isDisabled class
                                        <a className='isDisabled' key={member.name} href={member.link} target="_blank" rel="noopener noreferrer">
                                            <div key={member.name} className="team-member">
                                                <img src={member.image} alt={member.name} className="member-image" />
                                                <div className="member-info">
                                                    <h3 className="member-name">{member.name}</h3>
                                                    <p className="member-role">{member.role}</p>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;