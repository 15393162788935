// src/constants/places.js
import { img_leuven, img_hotel, img_eat, img_drink} from './images';

export const places = [
    { name: "City hall", location: "Center", alt: "City hall", src: img_leuven.leuven_cityhall, link: "https://www.visitleuven.be/en/town-hall" },
    { name: "University Library & Tower", location: "Center", alt: "Library", src: img_leuven.leuven_library, link: "https://www.visitleuven.be/en/university-library" },
    { name: "Abdij van Park", location: "Outer Ring", alt: "Abdij van Park", src: img_leuven.leuven_abdij, link: "https://www.visitleuven.be/en/abbeys/park-abbey" },
    { name: "Museum PARCUM", location: "Outer Ring", alt: "Museum PARCUM", src: img_leuven.leuven_parcum, link: "https://www.parcum.be/en/museum" },
    { name: "Museum M Leuven", location: "Center", alt: "Museum M", src: img_leuven.leuven_m, link: "https://www.visitleuven.be/en/mleuven" },
    { name: "Great Beguinage", location: "Center", alt: "xxx", src: img_leuven.leuven_beguinage, link: "https://www.visitleuven.be/en/great-beguinage" },
    { name: "Arenberg Cattle and Park", location: "Center", alt: "xxx", src: img_leuven.leuven_arenberg, link: "https://www.visitleuven.be/en/arenberg-castle" },
    { name: "Oude Markt", location: "Center", alt: "xxx", src: img_leuven.leuven_oude, link: "https://www.visitleuven.be/en/oude-markt" },
];

export const places_eat = [
    { eat_cat: [1,2], name: "Bite", location: "Center", alt: "Bite", src: img_eat.eat_bite, link: "https://biteleuven.be/", location_link: "https://maps.app.goo.gl/jGwN8vH6axPV7Dph6", price: "€€" },
    { eat_cat: [2], name: "Life Bar", location: "Center", alt: "Life Bar", src: img_eat.eat_lifebar, link: "https://lifebar.be/", location_link: "https://maps.app.goo.gl/uN1aBV35pxP4HELe7", price: "€€" },
    { eat_cat: [1,2], name: "Nosh", location: "Center", alt: "Nosh", src: img_eat.eat_nosh, link: "https://www.nosh.be/", location_link: "https://maps.app.goo.gl/PK8y4RpTtB8awmdB7", price: "€€" },
    { eat_cat: [3], name: "Het Moorinneken", location: "Center", alt: "Het Moorinneken", src: img_eat.eat_hetmoor, link: "https://moorinneken.be/", location_link: "https://maps.app.goo.gl/B5Vx9PTudiddt5QQ6", price: "€€" },
    { eat_cat: [3], name: "Optimist", location: "Center", alt: "Optimist", src: img_eat.eat_optimist, link: "https://www.deoptimist.be/", location_link: "https://maps.app.goo.gl/4ZNLj4mKK4wG3a7M6", price: "€" },
    { eat_cat: [3], name: "EED", location: "Center", alt: "EED", src: img_eat.eat_eed, link: "https://eedleuven.be/", location_link: "https://maps.app.goo.gl/cSp521VYd5rLVq7r5", price: "€€€" },
    { eat_cat: [3], name: "Nirvana Kitchen", location: "Center", alt: "Nirvana Kitchen", src: img_eat.eat_nirvana, link: "https://nirvanakitchenleuven.be/", location_link: "https://maps.app.goo.gl/KpxHGfFDJu9i7o3S9", price: "€€€" },
    { eat_cat: [3], name: "PepeNero", location: "Center", alt: "PepeNero", src: img_eat.eat_pepenero, link: "https://pepenero.be/", location_link: "https://maps.app.goo.gl/1AucnkRspy5DsjHk8", price: "€€" },
];

export const places_drink = [
    { drink_cat: 1, name: "Noir Coffeebar", location: "Center", alt: "Noir Coffeebar", src: img_drink.drink_noircoffeebar, link: "http://www.noir.coffee/", location_link: "https://maps.app.goo.gl/NPuHjnZuj1PTiEv8A", price: "€" },
    { drink_cat: 1, name: "Barboek", location: "Center", alt: "Barboek", src: img_drink.drink_barboek, link: "https://barboek.be/", location_link: "https://maps.app.goo.gl/sM2sAQt6HzJkhxBg6", price: "€" },
    { drink_cat: 2, name: "Malz", location: "Center", alt: "Malz", src: img_drink.drink_malz, link: "https://www.malz.be/", location_link: "https://maps.app.goo.gl/cRma5bijvBKJdVy1A", price: "€" },
    { drink_cat: 2, name: "Brouwerij De Coureur", location: "Center", alt: "De Coureur", src: img_drink.drink_decoureur, link: "https://brouwerijdecoureur.be/", location_link: "https://maps.app.goo.gl/36ygNVyufHfi3K4x9", price: "€" },
    // { drink_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
];


// BE CARFUL TO HAVE ONLY ONE ID PER ELEMENT
export const accommodations = [
    { id: 1, name: "Penta Hotel", price: "120~220", category:"100-200", distanceFoot: "5 mins", distancePublic: "5 mins", info: "Parking: 22€/day", src: img_hotel.accomod_penta, link: "https://www.pentahotels.com/hotels/belgium/leuven" },
    { id: 2, name: "Ibis budget Leuven Centrum", price: "70~140", category:"50-100", distanceFoot: "20 mins", distancePublic: "12 mins", info: "Parking: 22.6€/day", src: img_hotel.accomod_ibis, link: "https://all.accor.com/hotel/6682/index.nl.shtml" },
    { id: 3, name: "Martin’s Hotel", price: "120~200", category:"100-200", distanceFoot: "20 mins", distancePublic: "12 mins", info: "Parking: 30€/night", src: img_hotel.accomod_martin, link: "https://www.martinshotels.com/nl/page/martins-klooster/martins-klooster-4-hotel-leuven-officiele-website.11057.html" },
    { id: 4, name: "Theater Hotel Leuven", price: "120~200", category:"100-200", distanceFoot: "6 mins", distancePublic: "5 mins", info: "Public Parking: 20€/day", src: img_hotel.accomod_theater, link: "https://www.lodge-hotels.be/nl/hotels/b/theater-hotel-leuven" },
    { id: 5, name: "Hotel Carpinus", price: "95~150", category:"50-100", distanceFoot: "1h", distancePublic: "22 mins", info: "Free Public Parking", src: img_hotel.accomod_carpinus, link: "https://carpinus.eu/" },
    // Add more accommodations as needed
];
